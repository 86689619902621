import { About } from "../about/about";
import { Data } from "../data/data";
import { Idea } from "../idea/idea";
import { Service } from "../service/service";
import { Welcome } from "../welcome/welcome";
import "./style/style.module.css";

export function Main() {
  return (
    <main>
      <Welcome />
      <About />
      <Data />
      <Idea />
      <Service />
      {/* <Info /> */}
      {/* <Solution />
      <Journey /> */}
    </main>
  );
}
