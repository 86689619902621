import { Flex } from "../../common/flex/flex";
import style from "../style/style.module.css";

export function ServiceChildTeraphy() {
  return (
    <div className={style.card}>
      <Flex
        direction="row"
        align="flex-start"
        padding="16px 24px 10px 24px"
        style={{ fontSize: "20px", background: "rgba(0, 0, 0, 0.25)" }}
      >
        <h3>Çocuk-Ergen Terapisi</h3>
      </Flex>
      <Flex padding="18px 24px" flex="1">
        <Flex direction="row" align="center" gap="10">
          <span>
            Çocuklar ve ergenler için özel olarak tasarlanmış bir terapi
            sürecidir
          </span>
        </Flex>
        <Flex direction="row" align="center" gap="10">
          <span>
            Bu yaklaşım, genç bireylerin duygusal ve davranışsal zorluklarını
            ele alarak, gelişimsel ihtiyaçlarına uygun profesyonel destek sağlar
          </span>
        </Flex>
      </Flex>
      <Flex padding="12px 32px 24px 32px">
        <button style={{ background: "rgb(164, 115, 85)" }}>ILETISIM</button>
      </Flex>
    </div>
  );
}
