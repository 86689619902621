import "./App.css";
import { Main } from "./module/main/main";
import { Footer } from "./module/common/footer/footer";
import { Header } from "./module/common/header/header";
import { Loading } from "./module/loading/loading";

function App() {
  return (
    <div className="App">
      <Header />
      <Main />
      <Footer />
      <Loading />
    </div>
  );
}

export default App;
