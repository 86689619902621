import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import style from "./style/style.module.css";
import {
  faBars,
  faCalendarDays,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Flex } from "../flex/flex";
import { useState } from "react";
import { ScrollToSection } from "../function/scroll-to";

export function Header() {
  const [active, setActive] = useState(false);

  const navigation = [
    { href: "home", title: "Anasayfa" },
    { href: "about", title: "Hakkinda" },
    { href: "teraphy", title: "Online Terapi" },
    { href: "service", title: "Hizmetlerimiz" },
  ];

  return (
    <>
      <header className={style.header_desktop}>
        <Flex direction="row">
          <img
            alt=""
            src="img/pnr-logo-transparent.png"
            style={{
              width: "55px",
              objectFit: "contain",
              filter: "brightness(0.25)",
            }}
          />
        </Flex>
        <nav className={style.nav}>
          {navigation.map((item) => (
            <div onClick={() => ScrollToSection(item.href)}>{item.title}</div>
          ))}
          <button onClick={() => ScrollToSection("contact")}>
            <FontAwesomeIcon icon={faCalendarDays} />
            <span>RANDEVU</span>
          </button>
        </nav>
      </header>
      <header className={style.header_mobile}>
        <Flex direction="row">
          <img
            alt=""
            src="img/pnr-logo-transparent.png"
            style={{
              width: "45px",
              objectFit: "contain",
              filter: "brightness(0.25)",
            }}
          />
        </Flex>
        <div>
          <FontAwesomeIcon
            size="xl"
            icon={faBars}
            onClick={() => setActive(true)}
          />
        </div>
        {active && (
          <Flex className={style.nav_mobile} animation="data-anim-page-up">
            <Flex onClick={() => setActive(false)} align="flex-end">
              <FontAwesomeIcon icon={faXmark} size="xl" />
            </Flex>
            {navigation.map((item) => (
              <Flex
                delay={1}
                animation="data-anim-translate-up"
                onClick={() => {
                  ScrollToSection(item.href);
                  setActive(false);
                }}
              >
                <li>{item.title}</li>
              </Flex>
            ))}
            <Flex
              delay={6}
              animation="data-anim-translate-up"
              style={{ padding: "0 64px" }}
            >
              <button onClick={() => ScrollToSection("contact")}>
                <FontAwesomeIcon icon={faCalendarDays} />
                <span>RANDEVU</span>
              </button>
            </Flex>
          </Flex>
        )}
      </header>
    </>
  );
}
