import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";

export function About() {
  return (
    <section className={style.container} id="about">
      <div className={style.content}>
        <Flex className={style.img_container}>
          <img src="/img/about.png" alt="" className={style.img} />
        </Flex>
        <Flex style={{ textAlign: "start", gridColumn: "2" }} padding="0 32px">
          <p style={{ lineHeight: "32.4px", letterSpacing: "0.09px" }}>
            <strong
              style={{ color: "#000", fontSize: "22px", marginRight: "12px" }}
            >
              Pınar Şenadım,
            </strong>
            Lisans eğitimini Çağ Üniversitesi Psikoloji bölümünde, İngilizce
            olarak tamamladı. Eğitimi süresince çeşitli hastanelerin psikiyatri
            bölümlerinde gönüllü olarak hasta gözlemlerinde bulundu. Yüksek
            Lisans eğitimini, ‘Zorunlu Göç Eden Bireylerin Bağımlılık Profili
            ile Travma Sonrası Stres Bozukluğunun İlişkisi’ konulu tezi ile
            Çukurova Üniversitesinde tamamladı.
          </p>
          <br />
          <p style={{ lineHeight: "32.4px", letterSpacing: "0.09px" }}>
            Birleşmiş Milletler Uluslararası Göç Örgütü (International
            Organization for Migration, IOM) Psikolog/Terapist olarak görev
            aldı. Bağımlılık alanında hizmet veren Yeşilay Danışmanlık
            Merkezlerinde Uzman Psikolog olarak çalıştı. Ayrıca, Uluslararası
            Yeşilay Federasyonu’nda yurtdışındaki psikologlara süpervizyonlar
            verdi. Ulusal ve uluslararası kongrelerde eğitimler vermiştir.
          </p>
          <br />
          <p style={{ lineHeight: "32.4px", letterSpacing: "0.09px" }}>
            Travma Sonrası Stres Bozukluğu, Anksiyete, Depresyon, Öfke Kontrol
            Sorunları, Yeme Bozuklukları, Obsesif Kompulsif Bozukluk, Bağımlılık
            konularında uzmandır. Klinik çalışmalarında birey odaklı yaklaşımı
            benimseyerek; Bilişsel Davranışçı Terapi, Kabul ve Kararlılık
            Terapi, Şema Terapi, Mindfulness ile ilerlemektedir.
          </p>
        </Flex>
      </div>
    </section>
  );
}
