import { useEffect, useRef } from "react";
import style from "./style/style.module.css";
import { Flex } from "../common/flex/flex";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import { ScrollToSection } from "../common/function/scroll-to";

export function Welcome() {
  const ht = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    if (!ht.current) return;

    window.addEventListener("scroll", function () {
      if (!ht.current) return;

      let scrollPosition = window.pageYOffset;
      ht.current.style.top = -(scrollPosition * 0.35) + "px";
    });
  }, [ht.current]);

  return (
    <div className={style.container} id="home">
      <div ref={ht} className={style.video_container}>
        <video
          loop
          muted
          autoPlay
          src="/img/main3.mp4"
          className={style.video}
        ></video>
      </div>
      <div className={style.text}>
        <Flex direction="row">
          {/* <img
            alt=""
            src="/img/pnr-logo-transparent.png"
            style={{
              width: "70px",
              objectFit: "contain",
              filter: "grayscale(1)",
              transform: "translateY(-4px)",
            }}
          /> */}
          <Flex gap="0" align="flex-start" style={{ lineHeight: "32px" }}>
            <h1>Hoş geldiniz!</h1>
            <h3 style={{ fontWeight: "400", color: "#EEE" }}>
              Yeni bir başlangıç için doğru yerdesiniz.
            </h3>
            <br />
          </Flex>
        </Flex>
        <button
          onClick={() => ScrollToSection("contact")}
          className={style.welcome_button}
        >
          <FontAwesomeIcon icon={faMessage} />
          <span>Iletisime Gec</span>
        </button>
      </div>
    </div>
  );
}
