import { useEffect, useRef } from "react";
import style from "./style/style.module.css";

export function Loading() {
  const div = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!div.current) return;

    setTimeout(() => {
      if (!div.current) return;
      div.current.style.opacity = "0";
    }, 1500);
  }, []);
  return (
    <div className={style.container} ref={div}>
      <img className={style.logo} alt="" src="/img/pnr-logo.png" />
    </div>
  );
}
