import { Flex } from "../../common/flex/flex";
import style from "../style/style.module.css";

export function ServiceGroupTeraphy() {
  return (
    <div className={style.card}>
      <Flex
        direction="row"
        align="flex-start"
        padding="16px 24px 10px 24px"
        style={{ fontSize: "20px", background: "rgba(0, 0, 0, 0.25)" }}
      >
        <h3>Grup Terapi</h3>
      </Flex>
      <Flex padding="18px 24px" flex="1">
        <Flex direction="row" align="center" gap="10">
          <span>
            Terapist rehberliğinde, benzer sorunları yaşayan bireylerin bir
            araya geldiği psikoterapi yöntemidir.
          </span>
        </Flex>
        <Flex direction="row" align="center" gap="10">
          <span>
            Katılımcıların kendi deneyimlerini paylaşmalarına ve duygusal
            ifadelerini dile getirmelerine olanak tanır
          </span>
        </Flex>
      </Flex>
      <Flex padding="12px 32px 24px 32px">
        <button style={{ background: "rgb(164, 115, 85)" }}>ILETISIM</button>
      </Flex>
    </div>
  );
}
