import { Flex } from "../../common/flex/flex";
import style from "../style/style.module.css";

export function ServiceCoupleTeraphy() {
  return (
    <div className={style.card}>
      <Flex
        direction="row"
        align="flex-start"
        padding="16px 24px 10px 24px"
        style={{ fontSize: "20px", background: "rgba(0, 0, 0, 0.25)" }}
      >
        <h3>Çift Terapisi</h3>
      </Flex>
      <Flex padding="18px 24px" flex="1">
        <Flex direction="row" align="center" gap="10">
          <span>
            İlişkideki zorlukları aşmak ve sağlıklı iletişim becerileri
            geliştirerek bağınızı güçlendirmek için profesyonel çözüm sunar
          </span>
        </Flex>
        <Flex direction="row" align="center" gap="10">
          <span>
            Duygusal iyilik halinizi destekleyerek ilişkinizdeki dengeyi yeniden
            kurmanıza yardımcı olan psikoterapi yöntemidir
          </span>
        </Flex>
      </Flex>
      <Flex padding="12px 32px 24px 32px">
        <button style={{ background: "rgb(164, 115, 85)" }}>ILETISIM</button>
      </Flex>
    </div>
  );
}
