import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";

export const Data = () => {
  return (
    <section className={style.container}>
      <Flex className={style.content}>
        <label className={style.data_title}>Online Gorusme</label>
        <div style={{ height: "1px", background: "#FFFFFF33" }} />
        <span className={style.data_value}>+ 36000</span>
      </Flex>
      <Flex className={style.content}>
        <label className={style.data_title}>Deneyim</label>
        <div style={{ height: "1px", background: "#FFFFFF33" }} />
        <span className={style.data_value}>+ 10000</span>
      </Flex>
      <Flex className={style.content}>
        <label className={style.data_title}>Danisan</label>
        <div style={{ height: "1px", background: "#FFFFFF33" }} />
        <span className={style.data_value}>+ 500</span>
      </Flex>
      <Flex className={style.content}>
        <label className={style.data_title}>Grup Terapi</label>
        <div style={{ height: "1px", background: "#FFFFFF33" }} />
        <span className={style.data_value}>+ 100</span>
      </Flex>
    </section>
  );
};
