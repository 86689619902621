import style from "./style/style.module.css";
import { ServiceFamilyTeraphy } from "./components/services-family-teraphy";
import { ServiceAdultTeraphy } from "./components/service-adult-teraphy";
import { ServiceCoupleTeraphy } from "./components/service-couple-teraphy";
import { ServiceChildTeraphy } from "./components/service-child-teraph";
import { ServiceGroupTeraphy } from "./components/service-group-terapy";
import { ServiceDrugTeraphy } from "./components/service-drug-teraphy";

export interface ItemType {
  img: string;
  title: string;
  desc: string;
  price: string;
}
export function Service() {
  return (
    <section className={style.container} id="service">
      <div className={style.content}>
        <div className={style.items}>
          <ServiceAdultTeraphy />
          <ServiceFamilyTeraphy />
          <ServiceCoupleTeraphy />
          <ServiceChildTeraphy />
          <ServiceGroupTeraphy />
          <ServiceDrugTeraphy />
        </div>
      </div>
    </section>
  );
}
