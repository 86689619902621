import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import style from "./style/style.module.css";
import { Flex } from "../flex/flex";
import emailjs from "emailjs-com";
import { useState } from "react";
import { config } from "../../../config";
import { Input } from "../input/input";

export function Footer() {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  async function sendEmail() {
    if (email.name === "") {
      alert("Lutfen isim alanini doldurunuz");
      return;
    }
    if (email.phone === "") {
      alert("Lutfen telefon alanini doldurunuz");
      return;
    }
    try {
      emailjs.init(config.email_key);
      setIsLoading(true);
      await emailjs.send(config.email_service, config.email_template, {
        from_email: email.email,
        from_phone: email.phone,
        from_name: email.name,
        message: email.message,
      });
      alert("Email sent successfully!");
      setEmail({ ...email, name: "", message: "", email: "" });
    } catch (error) {
      console.error("Failed to send email", error);
    }

    setIsLoading(false);
  }
  return (
    <footer id="contact">
      <div className={style.content}>
        <Flex
          gap="0"
          align="flex-start"
          style={{
            fontSize: "26px",
            lineHeight: "32px",
          }}
        >
          <h2>Hazır Mısınız ?</h2>
          <p style={{ textAlign: "start" }}>
            Terapistinizle ücretsiz 15 dakikalık görüşmenizi hemen planlayın.
          </p>
        </Flex>
        <div className={style.message}>
          <div className={style.rez}>
            <Input
              title="Ad Soad *"
              value={email.name}
              onChange={(e) => setEmail({ ...email, name: e.target.value })}
            />
            <Input
              title="Email"
              value={email.email}
              onChange={(e) => setEmail({ ...email, email: e.target.value })}
            />
            <Input
              title="Telefon *"
              value={email.phone}
              onChange={(e) => setEmail({ ...email, phone: e.target.value })}
            />
            <Input
              title="Mesaj"
              value={email.message}
              onChange={(e) => setEmail({ ...email, message: e.target.value })}
            />
            <div>
              <br />
              <button onClick={sendEmail} disabled={isLoading}>
                <FontAwesomeIcon icon={faPaperPlane} />
                <span>GONDER</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={style.copy_right}>
        <label>
          {`Copyright © by `}
          <strong>
            <img
              alt=""
              src="/img/island-software.png"
              width={40}
              height={40}
              style={{ transform: "translateY(10px)" }}
            />
            {` Island Software `}
          </strong>
          {` 2024. All rights reserved.`}
        </label>
      </div>
    </footer>
  );
}
