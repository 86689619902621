import { Flex } from "../common/flex/flex";
import style from "./style/style.module.css";

export function Idea() {
  return (
    <section className={style.container} id="teraphy">
      <div className={style.content}>
        <Flex gap="0">
          <h1 className={style.header_title}>
            Günümüzün hızlı tempolu dünyasında, zaman ve mekân sınırlamaları
            sizi zorlamasın
          </h1>
          <label className={style.header_desc}>
            Yaşam kalitenizi artırmanıza yardımcı modern terapi şekli.
          </label>
        </Flex>
        <div className={style.grid}>
          <div className={style.item_container}>
            <div className={style.items_icon_container}>
              <div className={style.items_icon} />
              <div className={style.items_seperator} />
              <div className={style.items_icon} />
              <div className={style.items_seperator} />
              <div className={style.items_icon} />
            </div>
            <div className={style.items}>
              {[
                {
                  name: "Bilimsel Yaklaşım",
                  desc: "Online terapi, en güncel psikoterapi teknikleriyle bilimsel temellere dayanarak hizmet sunar.",
                },
                {
                  name: "Erişim Kolaylığı",
                  desc: "Evde veya iş yerinizde, istediğiniz her yerden yalnızca bir cihaz ve internet bağlantısı ile terapistinize kolayca erişim sağlayabilirsiniz.",
                },
                {
                  name: "Gizlilik",
                  desc: "Online terapi, güvenli bir ortamda yapılır ve kişisel bilgileriniz korunur.",
                },
              ].map((item) => (
                <div className={style.item}>
                  <h3>{item.name}</h3>
                  <label>{item.desc}</label>
                </div>
              ))}
            </div>
          </div>
          <div
            style={{
              overflow: "hidden",
              borderRadius: "32px",
              position: "relative",
            }}
          >
            <video
              loop
              muted
              autoPlay
              src="/img/video_2.mp4"
              className={style.video}
            ></video>
          </div>
        </div>
      </div>
    </section>
  );
}
